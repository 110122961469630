import axios from '@/api';

export function getCashList() {

	return new Promise((resolve) => {
		axios.get('/cash/list')
		.then((response) => {
			resolve(response.data);
		});
	});
}


export function exeCard(posts) {
	return new Promise((resolve, reject) => {
		axios.post('/cash/card/settle', posts)
		.then(response => {
			if (response.data.errNo === 0) {
				resolve(response.data);
			}
			else {
				reject(response.data)
			}
		})
		.catch(err => {
			reject(err);
		});
	});
}


export function exeBank(appCash) {
	return new Promise((resolve, reject) => {
		axios.post('/cash/bank/apply', {cashMoney: appCash, codePT: 'B'})
		.then((response) => {
			if (response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}


export function getChargeList(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cash/charge', {params: {page: params.page, pageLimit: params.pageLimit, schDateS: params.schDateS, schDateE: params.schDateE}})
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export function getUseList(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cash/use', {params: {page: params.page, pageLimit: params.pageLimit, schDateS: params.schDateS, schDateE: params.schDateE}})
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function getTerms(kind)
{
	return new Promise((resolve, reject) => {
		axios.get('/etc/terms', {params: {termsIdx: kind}})
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}






export function getIdExist(memberID) {
	return new Promise((resolve, reject) => {
		axios.post('/member/id-exist', {memberID: memberID}).then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		});
	})
}


export function Join(datas) {
	return new Promise((resolve, reject) => {
		//var frm 	= new FormData();
		axios.post('/member/join', datas)
		.then((response) => {
			console.log('response', response);
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			console.log(err);
			reject(err);
		});
	});
}

export function hpAuthSend(hp) {
	return new Promise((resolve, reject) => {
		axios.post('/member/hp-auth-send', {hp: hp})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}

export function hpAuthChk(hp, hpAuth) {
	return new Promise((resolve, reject) => {
		axios.post('/member/hp-auth-chk', {hp: hp, hpAuth: hpAuth})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export function emailChk(email) {
	return new Promise((resolve, reject) => {
		axios.post('/member/email-chk', {email})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function getFaqList(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cs/faq', { params: { page: params.page, schLimit: params.pageLimit, schWord: params.schWord } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function getNoticeList(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cs/notice', { params: { page: params.page, schLimit: params.pageLimit } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function getNoticeDetail(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cs/notice/detail', { params: { seq: params.seq } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function getEventList(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cs/event', { params: { page: params.page, schLimit: params.pageLimit } })
		.then((response) => {			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export function getEventDetail(params)
{
	return new Promise((resolve, reject) => {
		axios.get('/cs/event/detail', { params: { seq: params.seq } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export function hpAuthSendID(hp) {
	return new Promise((resolve, reject) => {
		axios.post('/member/hp-auth-send-id', {hp: hp})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}


export function findID(memberName, hp, hpAuth) {
	return new Promise((resolve, reject) => {
		axios.post('/member/id-find', {memberName: memberName, hp: hp, hpAuth: hpAuth})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}

export function findPW(memberID, memberName, hp, hpAuth) {
	return new Promise((resolve, reject) => {
		axios.post('/member/pw-find', {memberID, memberName: memberName, hp: hp, hpAuth: hpAuth})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}


export function pwReset(posts) {
	return new Promise((resolve, reject) => {
		axios.post('/member/pw-reset', {
			memberIdx: posts.memberIdx, pwChk: posts.pwChk, newPW: posts.newPW, newPWChk: posts.newPWChk
		})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}


export function qnaWrite(posts) {
	return new Promise((resolve, reject) => {
		axios.post('/cs/qna/write', {codeQA: posts.codeQA, subject: posts.subject, qContent: posts.qContent})
		.then((response) => {
			if (response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}

export function qnaList(params) {
	return new Promise((resolve, reject) => {
		axios.get('/cs/qna/', { params: { page: params.page, schLimit: params.pageLimit } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}



export function bnrList(params) {
	return new Promise((resolve, reject) => {
		axios.get('/etc/banners', { params: { siteFlag: params.siteFlag, bnrFlag: params.bnrFlag } })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}


export function bnrClick(bSeq) {
	return new Promise((resolve, reject) => {
		axios.post('/etc/banner-click', { bSeq: bSeq })
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}


export function popupList() {
	return new Promise((resolve, reject) => {
		axios.get('/etc/popups')
		.then((response) => {
			
			if(response.data.errNo == 0) {
				resolve(response.data);
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}


export function getMyInfo() {
	return new Promise((resolve, reject) => {
		axios.get('/mypage/info')
		.then((response) => {
			if (response.data.errNo === 0) {
				resolve(response.data)
			}
			else {
				reject(response.data);
			}
		})
		.catch((err) => {
			reject(err);
		})
	})
}

export function hpAuthSendMy(hp) {
	return new Promise((resolve, reject) => {
		axios.post('/mypage/hp-auth-send', {hp: hp})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			resolve(err);
		});
	});
}

export function Modify(datas) {
	return new Promise((resolve, reject) => {
		axios.post('/mypage/modify', datas)
		.then((response) => {
			console.log('response', response);
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			console.log(err);
			reject(err);
		});
	});
}


export function PasswdChange(datas) {
	return new Promise((resolve, reject) => {
		axios.post('/mypage/passwd-change', datas)
		.then((response) => {
			console.log('response', response);
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			console.log(err);
			reject(err);
		});
	});
}

export function MemberOut(datas) {
	return new Promise((resolve, reject) => {
		axios.post('/mypage/out', datas)
		.then((response) => {
			console.log('response', response);
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			console.log(err);
			reject(err);
		});
	});
}


export function coodeMemberOut() {
	return new Promise((resolve, reject) => {
		axios.get('/mypage/code-memberOut')
		.then((response) => {
			console.log('response', response);
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}
		})
		.catch((err) => {
			console.log(err);
			reject(err);
		});
	});
}

