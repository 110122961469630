<template>
  <div>
    <section class="banners">
      <ul>
        <li>
          <transition name="banner">
            <div v-if="bannerImg.srcs.length > 0">
              <img
                :src="bannerImg.src"
                @click="openBanner(bannerImg.seq)"
                :class="[bannerImg.bnrLink == '' ? '' : 'cursor']"
              />
            </div>
          </transition>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup>
import { reactive, defineProps } from "vue";
import * as api from "@/api/api";

const props = defineProps({
  bnrFlag: {
    type: String,
  },
});

const bannerImg = reactive({
  width: 1110,
  height: 158,
  cnt: 0,
  srcs: [],
  src: null,
  index: 0,
  seq: null,
  seqs: [],
  bnrLink: null,
  bnrLinks: [],
});

const getBanners = (bnrFlag) => {
  api
    .bnrList({
      siteFlag: "W",
      bnrFlag: bnrFlag,
    })
    .then((rtns) => {
      // console.log(rtns);

      for (var i = 0; i < rtns.datas.length; i++) {
        bannerImg.srcs[i] = rtns.datas[i].bnrUrl;
        bannerImg.seqs[i] = rtns.datas[i].bSeq;
        bannerImg.bnrLinks[i] = rtns.datas[i].bnrLink;
      }

      bannerImg.src = bannerImg.srcs[0];
      bannerImg.seq = bannerImg.seqs[0];
      bannerImg.cnt = bannerImg.srcs.length;
      if (bannerImg.cnt > 1) {
        bannerRolling();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

const bannerRolling = () => {
  setInterval(() => {
    bannerImg.index++;
    bannerImg.src = bannerImg.srcs[bannerImg.index % bannerImg.cnt];
    bannerImg.seq = bannerImg.seqs[bannerImg.index % bannerImg.cnt];
    bannerImg.bnrLink = bannerImg.bnrLinks[bannerImg.index % bannerImg.cnt];
  }, 5000);
};

getBanners(props.bnrFlag);

const openBanner = (bSeq) => {
  // alert(bSeq);
  if (parseInt(bSeq, 10) > 0) {
    api
      .bnrClick(bSeq)
      .then((rtns) => {
        console.log("rtns: ", rtns.bnrLink);
        if (rtns.bnrLink !== "") {
          window.open(rtns.bnrLink);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
};
</script>

<style lang="scss" scoped>
.banner-enter,
.banner-leave-to {
  opacity: 100;
}
.banner-enter-active {
  transition: all 0.3s ease;
}
</style>
