<script setup>
import { reactive, ref, watch } from "vue";
import LogoImg from "@/components/imgs/Logo-img.vue";
import LogoImF from "@/components/imgs/Logo-img-footer.vue";

import * as apiEtc from "./api/etc";
import * as api from "./api/api";
import * as apiAuth from "./api/auth";
import router from "@/router/index.js";
import store from "@/store";

const datasEtc = ref([]);
apiEtc.getBusinessInfo().then((rtns) => {
	datasEtc.value = rtns;
});

const btnJoin = ref(null);
const bodyClass = ref(null);

const memberIdx = ref(sessionStorage.getItem("memberIdx"));
const memberID = ref(sessionStorage.getItem("memberID"));

const memberCash = ref(0);

const mobileMenu = ref(false);

if (memberIdx.value != null) {
	apiAuth.getMember().then((rtns) => {
		console.log("getMember", rtns);
		memberCash.value = rtns.memberCash;
	});
}

const goLogout = () => {
	apiAuth.exeLogout().then((rtns) => {
		sessionStorage.clear();
		localStorage.clear();
		console.log(rtns);
		document.location.href = "/";
	});
};

const openMobileMenu = () => {
	mobileMenu.value = true;
};

const closeMobileMenu = () => {
	mobileMenu.value = false;
};

const popup = reactive({
	title: null,
});

const openTerms = (title, kind) => {
	popup.title = title;
	popup.open = true;

	api
		.getTerms(kind)
		.then((rtns) => {
			console.log(rtns.datas.content);
			popup.content = rtns.datas.content;
		})
		.catch((err) => {
			alert("ERROR");
			console.log(err);
		});
};

const closePopup = () => {
	popup.open = false;
};



watch(
	() => store.getters.getBodyClass,
	() => {
		bodyClass.value = store.getters.getBodyClass;
	}
);

watch(
	() => store.getters.getBtnJoin,
	() => {
		btnJoin.value = store.getters.getBtnJoin;
	}
);

console.log(btnJoin);

const openLaLotto = (flag = "") => {
	let url = "https://www.calottery.com/";
	switch (flag) {
		case "MM":
			url = "https://www.megamillions.com/";
			break;
		case "PB":
			url = "https://powerball.com/";
			break;
	}
	window.open(url);
};
</script>

<template>
	<div id="mobileMenuWrap" class="MOBILE" v-bind="mobileMenu" v-show="mobileMenu == true">
		<div id="mobileMenu">
			<div class="mobileMenuIn">
				<ul>
					<li class="logo">
						<router-link to="/">
							<LogoImg type="MOBILE" />
						</router-link>
					</li>
					<li class="mobileMenutop">
						<div v-if="memberIdx == undefined">
							<div class="menu" @click="closeMobileMenu()">닫기</div>
							<div class="menu" v-show="btnJoin" @click="
								router.push('/join');
							closeMobileMenu();
							">
								회원가입
							</div>
							<div class="menu" @click="
								router.push('/login');
							closeMobileMenu();
							">
								로그인
							</div>
						</div>
						<div v-else>
							<div class="menu" @click="closeMobileMenu()">닫기</div>
							<div class="menu" @click="goLogout()">로그아웃</div>
						</div>
					</li>
				</ul>
				<div class="mobileMenuEtc">
					<h2 @click="
						router.push('/cs/service-chk');
					closeMobileMenu();
					">
						서비스 이용전 꼭 확인
					</h2>
					<h2 @click="
						router.push('/cs/notice');
					closeMobileMenu();
					">
						공지사항
					</h2>
					<h2 @click="
						router.push('/cs/event');
					closeMobileMenu();
					">
						이벤트
					</h2>
					<h2 @click="
						router.push('/cs/faq');
					closeMobileMenu();
					">
						FAQ
					</h2>
					<h2 @click="
						router.push('/cs/qna');
					closeMobileMenu();
					">
						1:1문의
					</h2>
					<h2 @click="
						openLaLotto('MM');
					closeMobileMenu();
					">
						메가밀리언 사이트
					</h2>
					<h2 @click="
						openLaLotto('PB');
					closeMobileMenu();
					">
						파워볼 사이트
					</h2>
					<h2 @click="
						router.push('/mypage/info');
					closeMobileMenu();
					">
						마이정보
					</h2>
				</div>
			</div>
		</div>
	</div>

	<div id="backGround">
		<div id="wrapper">
			<header>
				<ul id="header">
					<li class="logo">
						<router-link to="/">
							<LogoImg />
						</router-link>
					</li>
					<li class="body PC">
						<div id="loginBefore" v-if="memberIdx == undefined">
							<button class="login" id="btnJoin" v-show="btnJoin" @click="router.push('/join')">
								회원가입
							</button>
							<button class="login" id="btnLogin" @click="router.push('/login')">
								로그인
							</button>
						</div>

						<div id="loginAfter" v-else>
							<div class="logout">
								<button class="login" @click="router.push('/cs/qna')">1:1문의</button>
								<button class="login" @click="goLogout()">로그아웃</button>
							</div>

							<ul class="msg">
								<li class="welcome">
									{{ memberID }}({{ memberIdx }})님 환영합니다. 캐쉬내역 :
									<span>{{ memberCash.toLocaleString() }}</span>캐쉬
								</li>
								<li class="etcButns">
									<div class="etcButns">
										<button @click="router.push('/')">충전</button> |
										<button @click="router.push('/cash/use/list')">캐쉬내역</button>
									</div>
									<div class="myBtn">
										<button @click="router.push('/mypage/info')">마이정보</button>
									</div>
								</li>
							</ul>
						</div>
					</li>

					<li class="body MOBILE">
						<div v-if="memberIdx == undefined">
							<div class="menu" @click="openMobileMenu()">메뉴</div>
							<div class="menu" v-show="btnJoin" @click="router.push('/join')">
								회원가입
							</div>
							<div class="menu" @click="router.push('/login')">로그인</div>
						</div>
						<div v-else>
							<div class="menu" @click="openMobileMenu()">메뉴</div>
							<div class="menu" @click="router.push('/cs/qna')">1:1문의</div>
							<div class="menu" @click="goLogout()">로그아웃</div>
						</div>
					</li>
				</ul>
			</header>

			<div id="memberInfo" class="memberInfo MOBILE" v-if="memberIdx != undefined">
				<p>{{ memberID }}({{ memberIdx }})님 환영합니다.</p>
				<p>캐쉬내역 : {{ memberCash.toLocaleString() }}캐쉬</p>
				<hr />
				<ul>
					<li @click="router.push('/')">충전</li>
					<li @click="router.push('/cash/use/list')">캐쉬내역</li>
				</ul>
			</div>

			<div id="body" :class="bodyClass">
				<Suspense>
					<router-view />
				</Suspense>
			</div>

			<footer>
				<ul>
					<li class="PC">
						<LogoImF />
					</li>
					<li>
						<p class="teams">
							<span @click="openTerms('이용약관', 'U')">이용약관</span><span @click="openTerms('개인정보 처리방침', 'P')">개인정보
								처리방침</span>
						</p>
						<p>&nbsp;</p>
						<p>
							사업자 : {{ datasEtc.companyName }} &nbsp; &nbsp; 주소 :
							{{ datasEtc.address1 }} {{ datasEtc.address2 }} &nbsp; &nbsp; 고객문의 :
							{{ datasEtc.businessTel }} &nbsp; &nbsp; FAX : {{ datasEtc.businessFax }}
						</p>
						<p>
							대표자 : {{ datasEtc.ceoName }} &nbsp; &nbsp; 사업자등록번호 :
							{{ datasEtc.businessNo }} &nbsp; &nbsp; 통신판매사업자번호 :
							{{ datasEtc.intetnetBusiNo }}
						</p>
						<p>&nbsp;</p>
						<p>Copyright(c) 2019 MegaPowerKorea. All rights reserved.</p>
						<p>&nbsp;</p>
						<p>
							본 페이지는 게시된 이메일 주소가 자동 수집되는 것을 거부하며, 이를 위반 시
							정보통신망법에 의해 처벌됨을 유념하여 주시길 바랍니다.
						</p>
						<p>해당 서비스는 19세 이하는 이용할 수 없습니다.</p>
						<p>본 사이트는 Edge, Chrome, Safari 브라우저에 최적화되어 있습니다.</p>
					</li>
				</ul>
			</footer>
		</div>
	</div>

	<div id="popupWrap" v-bind="popup" v-show="popup.open == true">
		<div class="popupOut">
			<div class="popup">
				<ul>
					<li>{{ popup.title }}</li>
					<li><img src="@/assets/img/ico-close-x-big.png" @click="closePopup()" /></li>
				</ul>

				<div class="content" v-html="popup.content"></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import "@/assets/css/base.css", "@/assets/css/layout.css", "@/assets/scss/common.scss";
@import "@/assets/scss/desktop.scss";
@import "@/assets/scss/mobile.scss";
</style>
