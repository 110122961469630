import ajax from '@/api/index';


export function getBusinessInfo() {

	return new Promise((resolve) => {
		ajax.get('/etc/business-info')
		.then((response) => {
			resolve(response.data);
		});
	});
}


export function getGoodsPrice() {
	return new Promise((resolve) => {
		ajax.get('/goods/price')
		.then((response) => {
			resolve(response.data);
		});
	});
}