import axios from '@/api';

export function exeLogin(loginID, loginPW, idSave) {

	return new Promise((resolve, reject) => {
		axios.post('/member/login', {memberID: loginID, memberPW: loginPW, idSave: idSave})
		.then((response) => {
			if (response.data.errNo > 0) {
				reject(response.data);
			}
			else {
				resolve(response.data);
			}			
		});
	
	});
}


export function exeLogout() {
	return new Promise((resolve) => {
		axios.get('/member/logout')
		.then((response) => {
			resolve(response.data);
		});
	});
}


export function getMember() {
	return new Promise((resolve) => {
		axios.get('/member/index')
		.then((response) => {
			resolve(response.data);
		});
	});
}

