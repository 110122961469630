import axios from "axios";

const instance = axios.create({
	baseURL: '/api',
	withCredentials: true,
	headers: {
		"Content-type": "application/json",	
		// "Access-Control-Allow-Headers": "http://localhost:8080",
		// "Access-Control-Allow-Origin": "http://localhost:8080",
	}
});

// const instance = axios.create({
// 	baseURL: "http://api.megapower.de",
// 	credentials: "include",
// 	headers: {
// 		"Content-type": "application/json",	
// 	}
// });

// ajax.interceptors.request.use(
// 	(config) => {
// 		config.headers['Access-Control-Allow-Origin'] = '*';  // CORS 설정(모든 리소스 허용)
// 		return config;
// 	}
// )

//ajax.defaults.withCredentials = true; 

// axios.interceptors.response.use(response => {

// }, error =>  {

// };

export default instance;