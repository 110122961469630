import { createStore } from 'vuex'

export default createStore({
	state: {
		bgType: null,
		btnJoin: false,
		bodyClass: null,
		isLogin: false,
		agreeChk: false,
	},
	getters: {
		getBgType: (state) => {
			return state.bgType;
		},
		getBtnJoin: (state) => {
			return state.btnJoin;
		},
		getBodyClass: (state) => {
			return state.bodyClass;
		},

		isLogin: (state) => {
			return state.isLogin;
		},
		agreeChk: (state) => {
			return state.agreeChk;
		},
	},
	mutations: {
		setBgType: (state, value) => {
			state.bgType 	= value;
		},
		setBtnJoin: (state, bloon) => {
			state.btnJoin 	= bloon;
		},
		setBodyClass: (state, value) => {
			state.bodyClass 	= value;
		},

		setIsLogin: (state, bloon) => {
			state.isLogin 	= bloon;
		},

		setAgreeChk: (state, bloon) => {
			state.agreeChk = bloon;
		},
	},
	actions: {
	},
	modules: {
	}
})
